import axios from 'axios';
export default {
    getLocation(tenantId, locationId) {
        return axios.get(window.API_BASE + '/api/v1/' + tenantId + '/Locations/' + locationId);
    },

    addLocation(tenantId, params) {
        return axios.post(window.API_BASE + '/api/v1/' + tenantId + '/Locations', params);
    },

    updateLocation(tenantId, params) {
        return axios.put(window.API_BASE + '/api/v1/' + tenantId + '/Locations', params);
    },

    deleteLocation(tenantId, locationId) {
        return axios.delete(window.API_BASE + '/api/v1/' + tenantId + '/Locations/' + locationId);
    }
}